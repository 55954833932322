import marketsConfig from "config/markets.json"

import type { Nullish } from "~/@types/generics"
import i18nConfig from "~/config/i18n.json"
import { DEFAULT_CONTRY, DEFAULT_I18N, DEFAULT_LOCALE } from "~/lib/i18n/constants"
import type { I18n, Markets } from "~/lib/i18n/types"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { isLocale } from "~/lib/i18n/utils/is-locale"
import { objectEntries } from "~/utils/object-entries"

export function getI18n(locale: Nullish<string>) {
  if (isLocale(locale)) {
    return i18nConfig[locale]
  }
  return DEFAULT_I18N
}

export function getI18nKey<K extends keyof ReturnType<typeof getI18n>>(locale: Nullish<string | string[]>, key: K) {
  const i18n = getI18n(getProcessedLocale(locale))
  return i18n[key]
}

export function getShopifyLocale(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "shopify-locale")
}

export function getLang(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "lang")
}

export function getCMSLang(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "cms_lang")
}

export function getIntl(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "intl")
}

export function getMarket(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "market")
}

export function getLocations(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "locations")
}

export function getDimension(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "dimension")
}

export function getCurrency(locale: Nullish<string | string[]>) {
  return getI18nKey(locale, "currency")
}

export function getCountry(locale: Nullish<string | string[]>) {
  const market = getMarket(locale)

  // Get the first item of the choosen market
  if (!marketsConfig[market as Markets]?.sort()[0]) {
    console.warn(`[Market] Not founded market for the locale ${locale}`)
  }
  return marketsConfig[market as Markets]?.sort()[0] ?? DEFAULT_CONTRY
}

export function getMarketByCountry(country: string) {
  return Object.entries(marketsConfig).find(([, countries]) => countries.includes(country))?.[0]
}

export function getLocaleByCountry(country: string, countryRegion?: string): keyof I18n | undefined {
  const market = getMarketByCountry(country)

  const foundedEntriesWithCorrectMarket = objectEntries(i18nConfig).filter(([, i18n]) => i18n.market === market)
  const marketWithRegion =
    countryRegion && foundedEntriesWithCorrectMarket && foundedEntriesWithCorrectMarket?.length > 1
      ? foundedEntriesWithCorrectMarket?.find((entry) => {
          const [, market] = entry
          if ("regions" in market) {
            return (market.regions as string[])?.includes(countryRegion) ? [] : null
          }
          return null
        })?.[0] ?? foundedEntriesWithCorrectMarket?.[0]?.[0]
      : foundedEntriesWithCorrectMarket?.[0]?.[0] ?? DEFAULT_LOCALE

  return marketWithRegion
}
